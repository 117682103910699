/* eslint-disable */
import router from "../router";

// PAGE LOADING STATUS
export const checkCurrentRoute = () => {
  return router.history.current.meta.requiresAuth;
};

export const gtYears = () => {
  let now = new Date().getUTCFullYear();
  let years = Array(now - (now - 100))
    .fill("")
    .map((v, idx) => now - idx);
  // console.log(JSON.stringify(years));

  return years;
};

export const getCurrentYear = () => {
  let date = new Date();
  return date.getFullYear();
};

export const getCurrentdate = () => {
  var d = new Date();
  var month = new Array();
  var date = d.getDate();
  var year = d.getFullYear();

  month[0] = "Jan";
  month[1] = "Feb";
  month[2] = "Mar";
  month[3] = "Apr";
  month[4] = "May";
  month[5] = "Jun";
  month[6] = "Jul";
  month[7] = "Aug";
  month[8] = "Sept";
  month[9] = "Oct";
  month[10] = "Nov";
  month[11] = "Dec";
  var m = month[d.getMonth()];

  let currentD = date + "-" + m + "-" + year;

  return currentD;
};

// ########################       GENERAL        #################################

export const cashwyreURL = (state) => {
  return state.cashwyreURL;
};

export const AppId = (state) => {
  return state.AppId;
};

export const RequestId = () => {
  let text = "";
  let possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < 10; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
};

export const getPaylinkCategories = (state) => {
  return state.paylinkCategories;
};

export const getPaylinkCategoriesInfo = (state) => {
  return state.paylinkCategoriesInfo;
};

export const getPaylinkInfo = (state) => {
  return state.paylinkInfo;
};

export const getAssetRate = (state) => {
  return state.assetRate;
};

export const getBitcoinRate = (state) => {
  return state.bitcoinRate;
};

export const getAssetOptions = (state) => {
  return state.assetOptions;
};

// ########################           PAGE SETTINGS        #################################

export const currentSitePage = (state) => {
  return state.currentSitePage;
};

export const bannerSettings = (state) => {
  return state.bannerSettings;
};

// ########################           LOADERS        #################################

export const pageLoading = (state) => {
  return state.pageLoading;
};

export const pageProcessing = (state) => {
  return state.pageProcessing;
};

// ########################           MODALS        #################################

export const alertModalStatus = (state) => {
  return state.alertModalStatus;
};

export const alertModalType = (state) => {
  return state.alertModalType;
};

export const alertModalMessage = (state) => {
  return state.alertModalMessage;
};

export const actionProcessing = (state) => {
  return state.actionProcessing;
};

export const getIsModalActive = (state) => {
  return state.isModalActive;
};

export const getIsShareModalActive = (state) => {
  return state.isShareModalActive;
};
