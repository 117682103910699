<template>
    <footer id="footer">
        <div class="container">
            <FooterTop />
            <FooterBottom />
        </div>
    </footer>
</template>

<script>
import FooterTop from "./FooterTop.vue";
import FooterBottom from "./FooterBottom.vue";
export default {
    components: {
        FooterTop,
        FooterBottom,
    },
};
</script>

<style scoped>
#footer .container {
    padding-left: 0;
    padding-right: 0;
}
</style>
