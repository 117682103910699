<template>
    <div id="home-view">
        <CategoryAll />
    </div>
</template>

<script>
import CategoryAll from "@/components/home/CategoryAll.vue";

import { mapActions } from "vuex";
import "@/mixins";

export default {
    components: {
        CategoryAll,
    },

    methods: {
        ...mapActions(["setCurrentPage"]),
    },

    mounted() {
        this.setCurrentPage(this.$router.history.current.name);
    },
};
</script>

<style lang="scss" scoped>
</style>
