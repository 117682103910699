var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"progress-bar-container"},[_c('div',{staticClass:"donation"},[_c('span',{staticClass:"donated-amount"},[_vm._v(" $"+_vm._s(_vm.addComma(_vm.totalDonationVolume))+" ")]),_vm._v(" of "),_c('span',{staticClass:"donated-total"},[_vm._v("$"+_vm._s(_vm.addComma(_vm.targetAmountInAsset)))]),_vm._v(" raised ")]),_c('div',{staticClass:"progress-bar",style:({
            '--bar-width': _vm.barWidth,
        }),attrs:{"title":`$${_vm.totalDonationVolume} of ${_vm.targetAmountInAsset} raised`,"aria-label":`$${_vm.totalDonationVolume} of ${_vm.targetAmountInAsset} raised`}},[_c('div',{staticClass:"progress",style:({
                '--progress-value':
                    _vm.setProgressBar(
                        _vm.totalDonationVolume,
                        _vm.targetAmountInAsset
                    ) + '%',
            })})])])
}
var staticRenderFns = []

export { render, staticRenderFns }