<template>
    <div class="progress-bar-container">
        <div class="donation">
            <span class="donated-amount">
                ${{ addComma(totalDonationVolume) }}
            </span>
            of
            <span class="donated-total"
                >${{ addComma(targetAmountInAsset) }}</span
            >
            raised
        </div>

        <div
            class="progress-bar"
            :style="{
                '--bar-width': barWidth,
            }"
            :title="`$${totalDonationVolume} of ${targetAmountInAsset} raised`"
            :aria-label="`$${totalDonationVolume} of ${targetAmountInAsset} raised`"
        >
            <div
                class="progress"
                :style="{
                    '--progress-value':
                        setProgressBar(
                            totalDonationVolume,
                            targetAmountInAsset
                        ) + '%',
                }"
            ></div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["totalDonationVolume", "targetAmountInAsset", "barWidth"],

    methods: {
        setProgressBar(totalDonation, targetAmount) {
            const donationPercentage = (totalDonation / targetAmount) * 100;

            return donationPercentage;
        },
    },
};
</script>

<style lang="scss" scoped>
.progress-bar-container {
    .donation {
        font-size: 1rem;
        color: var(--grey2);
        margin-bottom: 10px;
        word-spacing: 2px;
        letter-spacing: 0.5px;

        .donated-amount {
            font-size: 1.25rem;
            font-weight: 600;
            color: var(--white);
        }
    }

    .progress-bar {
        position: relative;
        height: 8px;
        width: var(--bar-width);
        background: var(--black3);
        border-radius: 10px;

        .progress {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: var(--progress-value);
            background: var(--orangeDark);
            border-radius: 10px 0 0 10px;
            -o-animation: progress-bar 2s ease forwards;
            -moz-animation: progress-bar 2s ease forwards;
            -webkit-animation: progress-bar 2s ease forwards;
            animation: progress-bar 2s ease forwards;

            @-o-keyframes progress-bar {
                from {
                    width: 0%;
                }
                to {
                    width: var(--progress-value);
                }
            }
            @-moz-keyframes progress-bar {
                from {
                    width: 0%;
                }
                to {
                    width: var(--progress-value);
                }
            }
            @-webkit-keyframes progress-bar {
                from {
                    width: 0%;
                }
                to {
                    width: var(--progress-value);
                }
            }
            @keyframes progress-bar {
                from {
                    width: 0%;
                }
                to {
                    width: var(--progress-value);
                }
            }
        }
    }
}
</style>