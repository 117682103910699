// import mixins from "@/mixins";

// ########################           PAGE SETTINGS        #################################
export const CURRENT_SITE_PAGE = (state, payload) => {
  state.currentSitePage = payload;
};

export const BANNER_SETTINGS = (state, payload) => {
  state.bannerSettings = {
    title: payload.title,
    description: payload.description,
    prevLinkText: payload.prevLinkText,
    currentLinkText: payload.currentLinkText,
    buttonUrl: payload.buttonUrl,
    imageUrl: payload.imageUrl,
  };
};

// ########################           LOADERS        #################################

export const SET_ACTION_LOADING = (state, payload) => {
  setTimeout(() => {
    state.actionProcessing = payload;
  }, 200);
};

export const SET_PAGE_PROCESSING = (state, status) => {
  state.pageProcessing = status;
};

export const SET_PAGE_LOADING = (state, payload) => {
  state.pageLoading = {
    status: payload.status,
    message: payload.message,
    payload: payload.payload,
  };
};

// ########################           MODALS        #################################

export const SET_ALERT_MODAL_STATUS = (state, payload) => {
  state.alertModalStatus = payload.status;
  state.alertModalType = payload.type;
  state.alertModalMessage = payload.message;
};

export const TOGGLE_IS_MODAL_ACTIVE = (state, status) => {
  state.isModalActive = status;
};

export const TOGGLE_IS_SHARE_MODAL_ACTIVE = (state) => {
  state.isShareModalActive = !state.isShareModalActive;
};

// ########################          SERVICES        #################################

export const SAVE_PAYLINK_CATEGORIES = (state, payload) => {
  state.paylinkCategories = payload;
};

export const SAVE_PAYLINK_CATEGORIES_INFO = (state, payload) => {
  state.paylinkCategoriesInfo = payload;
};

export const SAVE_PAYLINK__INFO = (state, payload) => {
  state.paylinkInfo = payload;
};

export const SAVE_ASSET_RATE = (state, payload) => {
  state.assetRate = payload;
};

export const SAVE_BITCOIN_RATE = (state, payload) => {
  state.bitcoinRate = payload;
};

export const SAVE_ASSET_OPTIONS = (state, payload) => {
  state.assetOptions = payload;
};
