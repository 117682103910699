<template>
    <div id="app">
        <TheNavbarIndex />

        <transition name="fade" mode="out-in">
            <router-view />
        </transition>

        <MessageLoadingOverlay />
        <ActionLoadingOverlay />

        <!-- <TheFooter /> -->
    </div>
</template>
<script>
import TheNavbarIndex from "./components/header/TheNavbarIndex.vue";
// import TheFooter from "@/components/footer/TheFooter.vue";

import MessageLoadingOverlay from "./components/modal/MessageLoadingOverlay";
import ActionLoadingOverlay from "./components/modal/ActionLoadingOverlay";

import { mapActions } from "vuex";
// import $ from "jquery";

export default {
    components: {
        MessageLoadingOverlay,
        ActionLoadingOverlay,
        TheNavbarIndex,
        // TheFooter,
    },

    methods: {
        ...mapActions([
            // "fetchContactInfo",
            "savePaylinkCategories",
            "savePaylinkCategoriesInfo",
        ]),

        // getAppAuthentication: async function () {
        //     this.getAuthentication().then((response) => {
        //         console.log(response);

        //         // let appToken = response.data.value.token;
        //         // if (appToken) {
        //         //     this.axios.defaults.headers.common["Authorization"] =
        //         //         "bearer " + appToken;

        //         //     this.fetchAllInvestment();
        //         //     // this.getFeedbacks({
        //         //     //     userCode: "",
        //         //     //     from: null,
        //         //     //     to: null,
        //         //     //     page: 1,
        //         //     // });
        //         //     // this.getPositiveFeedbacks({
        //         //     //     userCode: "",
        //         //     //     from: null,
        //         //     //     to: null,
        //         //     //     page: 1,
        //         //     // });
        //         // }
        //     });
        // },
    },

    mounted() {
        this.savePaylinkCategories();
        this.savePaylinkCategoriesInfo();
    },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i,500,500i,600,600i,700,700i&amp;display=swap");

@import url("../public/assets/css/custom.css");
@import url("../public/assets/css/themify-icons.css");

:root {
    --orangeLight: #f89a02;
    --orangeDark: #c57705;
    --offOrange: #f8d194;
    --black0: #000;
    --black1: #161719;
    --black2: rgba(255, 255, 255, 0.05);
    --black3: #38393a;
    --brown: #9e8663;
    --white: #fff;
    --offWhite: #fffaf2;
    --grey: #e0e0e0;
    --grey1: #cec7c7;
    --grey2: #a3a3a3;
    --grey2-transp: #ababab55;

    // --- gradients ---
    // F8E402, C57705
    // background: linear-gradient(180deg, #F89A02 0%, #C57705 46.35%, rgba(197, 119, 5, 0.81) 78.12%);
    --errorColor: #ff3232;
}

#app,
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow-x: hidden;
    background: var(--black1);
}

#app {
    // height: 100vh;
    overflow-y: hidden;
}

/* scrollbar */
body::-webkit-scrollbar,
body::-webkit-scrollbar-track,
body::-webkit-scrollbar-thumb {
    -o-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

body::-webkit-scrollbar {
    width: 10px;
}

/* Track */
body::-webkit-scrollbar-track {
    background: var(--black1);
    // border-radius: 10px;

    &:hover {
        background-color: rgba(0, 0, 0, 0.97);
    }
}

/* Handle */
body::-webkit-scrollbar-thumb {
    background: var(--black2);
    // border-radius: 10px;

    &:hover {
        background: var(--grey2);
    }
}

/* selection or highlight */
::selection {
    background-color: var(--orangeDark);
    color: var(--white);
}

::-moz-selection {
    background-color: var(--orangeDark);
    color: var(--white);
}

// page transition
.fade-enter-active,
.fade-leave-active {
    -o-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
    /* transform: scale(0.9); */
}

.page-enter,
.page-leave-to {
    opacity: 0;
    -o-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
}

.page-enter-active,
.page-leave-active {
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.page-enter-to,
.page-leave {
    opacity: 1;
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
    -moz-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

// components transitions -----
// zoom in
.zoomIn-enter-active,
.zoomIn-leave-active {
    -o-transition: 0.05s ease 0s all;
    -moz-transition: 0.05s ease 0s all;
    -webkit-transition: 0.05s ease 0s all;
    transition: 0.05s ease 0s all;
}

.zoomIn-enter-from,
.zoomIn-leave-to {
    -o-transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
}

a,
i,
button {
    -o-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease;
}

a {
    text-decoration-skip-ink: auto;
}

i,
a[class|="simple"]:hover {
    color: var(--orangeDark);
}

[class|="sectiontitle-element"] div[class|="toptitle"],
[class|="sectiontitle-element"] span[class|="toptitle"] {
    color: var(--orangeLight);
    text-transform: capitalize;
}

[class|="btn"][class*="-big"][class*="-light"][class*="-border"],
[class|="btn"][class*="-lg"][class*="-light"][class*="-border"],
[class|="btn"][class*="-md"][class*="-light"][class*="-border"],
[class|="btn"][class*="-medium"][class*="-light"][class*="-border"],
[class|="btn"][class*="-sm"][class*="-light"][class*="-border"],
[class|="btn"][class*="-small"][class*="-light"][class*="-border"],
[class|="btn"][class*="-xl"][class*="-light"][class*="-border"] {
    text-transform: capitalize;
}

[class|="btn"][class*="-big"][class*="-light"][class*="-border"]:hover,
[class|="btn"][class*="-lg"][class*="-light"][class*="-border"]:hover,
[class|="btn"][class*="-md"][class*="-light"][class*="-border"]:hover,
[class|="btn"][class*="-medium"][class*="-light"][class*="-border"]:hover,
[class|="btn"][class*="-sm"][class*="-light"][class*="-border"]:hover,
[class|="btn"][class*="-small"][class*="-light"][class*="-border"]:hover,
[class|="btn"][class*="-xl"][class*="-light"][class*="-border"]:hover {
    color: var(--orangeLight);
}

/* ------- 
for PayBanner and NotFound 
------- */

// all header styles copied starts here -----

#header.uniqueHeaderClass {
    box-shadow: 0 0.125rem 0.125rem rgb(0 0 0 / 5%);
}

#header.uniqueHeaderClass[class*="transparent"]
    nav.main-navigation
    #main-menu
    [class|="menu-button"]
    .bar {
    background-color: var(--black2);
}

header[class*="transparent"][class*="moove"]
    nav.main-navigation
    #main-menu
    [class|="menu-button"]
    .bar {
    background-color: var(--white);
}

#header.uniqueHeaderClass
    nav.main-navigation
    #main-menu
    .menu-holder
    > ul
    > li
    > a {
    color: var(--black2);
}

#header.uniqueHeaderClass
    nav.main-navigation
    #main-menu
    .cta
    a[class|="btn"][class*="border"] {
    border-color: var(--black2);
    color: var(--black2);
}

header[class*="transparent"][class*="moove"] {
    box-shadow: 0 0.125rem 0.125rem rgb(0 0 0 / 5%);
}

header[class*="transparent"]:before {
    // background-color: transparent;
    background-color: var(--black2);
}

header[class*="transparent"][class*="moove"]:before {
    // background-color: var(--black2);
    background-color: rgba(22, 23, 25, 0.935) !important;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    box-shadow: 0 0.125rem 0.125rem rgb(0 0 0 / 15%);
}

header[class*="transparent"][class*="moove"]
    nav.main-navigation
    #main-menu
    .menu-holder
    > ul
    > li
    > a,
#header.uniqueHeaderClass
    nav.main-navigation
    #main-menu
    .menu-holder
    ul
    li[class*="dropdown-parent"][class*="icon"]
    > a:after {
    // color: var(--black2);
    color: rgba(255, 255, 255, 0.7);
}

header[class*="transparent"][class*="moove"]
    nav.main-navigation
    #main-menu
    .menu-holder
    ul
    li[class*="dropdown-parent"][class*="icon"]
    > a:after {
    color: var(--white);
}

header[class*="transparent"][class*="moove"]
    nav.main-navigation
    #main-menu
    .menu-holder
    > ul
    > li
    > a:hover {
    opacity: 1;
    color: var(--white);
}

header[class*="transparent"][class*="moove"]
    nav.main-navigation
    #main-menu
    .cta
    a[class|="btn"][class*="border"] {
    border-color: var(--white);
    color: var(--white);
}

header[class*="transparent"]
    nav.main-navigation
    #main-menu
    .menu-holder
    ul
    li
    a {
    padding-top: 15px;
    padding-bottom: 15px;
}

header
    nav.main-navigation
    #main-menu
    .menu-holder
    ul
    li[class*="dropdown-parent"]
    [class|="dropdown-inner"]
    ul
    li
    a {
    padding-top: 0;
    padding-bottom: 0;
}

header[class*="transparent"]
    nav.main-navigation
    #main-menu
    .menu-holder
    > ul
    > li
    > a.active,
header[class*="transparent"]
    nav.main-navigation
    #main-menu
    .menu-holder
    > ul
    > li
    > a.active-item {
    -moz-text-decoration: none;
    text-decoration: none;
    border-bottom-width: 2px;
    border-style: solid;
}

header[class*="transparent"][class*="moove"]
    nav.main-navigation
    #main-menu
    .menu-holder
    > ul
    > li
    > a.active {
    color: var(--white);
    // border-color: var(--white);
}

header nav.main-navigation #main-menu .menu-holder ul li a {
    &:hover,
    &.active {
        color: var(--orangeDark);
        text-decoration: none;
    }
}

header[class*="transparent"]
    nav.main-navigation
    #main-menu
    .menu-holder
    ul
    li[class*="dropdown-parent"][class*="icon"]
    > a:after {
    opacity: 0.7;
    top: 50%;
    -o-transform: translateY(-35%);
    -ms-transform: translateY(-35%);
    -moz-transform: translateY(-35%);
    -webkit-transform: translateY(-35%);
    transform: translateY(-35%);
}

header nav.main-navigation #logo a.navbar-brand {
    max-width: 120px !important;

    & img {
        max-height: initial !important;
    }
}

header nav.main-navigation #main-menu .menu-holder + [class|="cta"],
header nav.main-navigation #main-menu .menu-holder + [class|="menu-button"],
header nav.main-navigation #main-menu .menu-holder + [class|="shortlinks"],
header nav.main-navigation #top-menu .menu-holder + [class|="cta"],
header nav.main-navigation #top-menu .menu-holder + [class|="menu-button"],
header nav.main-navigation #top-menu .menu-holder + [class|="shortlinks"],
header nav.top-navigation #main-menu .menu-holder + [class|="cta"],
header nav.top-navigation #main-menu .menu-holder + [class|="menu-button"],
header nav.top-navigation #main-menu .menu-holder + [class|="shortlinks"],
header nav.top-navigation #top-menu .menu-holder + [class|="cta"],
header nav.top-navigation #top-menu .menu-holder + [class|="menu-button"],
header nav.top-navigation #top-menu .menu-holder + [class|="shortlinks"] {
    margin-left: 4rem;
    margin-right: 0.5rem;
}

// all header styles copied starts here -----

[class|="sectiontitle-element"] h2,
[class|="sectiontitle-element"] h3 {
    font-size: 2.5rem;

    @media (max-width: 768px) {
        font-size: 2rem;
    }
}

.toptitle {
    position: relative;
    // padding-left: 1rem;

    // &::before {
    //     content: "";
    //     position: absolute;
    //     top: 0;
    //     bottom: 0;
    //     height: 2px;
    //     width: 5px;
    //     margin: auto;
    //     margin-left: -10px;
    //     background: var(--orangeLight);
    //     border-radius: 5px;
    // }
}

.find_out_more_container a {
    border-color: var(--orangeDark) !important;
    color: var(--orangeDark) !important;
    text-transform: capitalize;

    &:hover {
        background: var(--orangeDark) !important;
        color: var(--white) !important;
    }
}

// carousels
// --------countries

// --------quotes

// pagination
#pagination {
    .VuePagination__count {
        display: none;
    }

    .VuePagination__pagination {
        display: flex;
        justify-content: center;

        @media screen and (max-width: 426px) {
            flex-wrap: wrap;

            .VuePagination__pagination-item {
                // flex: 1;
                // order: 1;
                margin-bottom: 1.5rem;
            }

            // .VuePagination__pagination-item.VuePagination__pagination-item-prev-page {
            //     order: 2;
            // }

            // .VuePagination__pagination-item.VuePagination__pagination-item-next-page {
            //     order: 3;
            // }
        }

        .VuePagination__pagination-item {
            width: 40px;
            height: 40px;
            font-weight: 500;
            font-size: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px 14px;
            margin-left: 1px;
            margin-right: 1px;
            line-height: 20px;
            border-radius: 8px;
            cursor: pointer;

            &:not(.disabled):hover {
                background: rgba(248, 209, 148, 0.1);
            }

            &.active,
            &.active:hover {
                background: linear-gradient(
                    180deg,
                    rgba(248, 154, 2, 0.15) 0%,
                    rgba(197, 119, 5, 0.15) 46.35%,
                    rgba(197, 119, 5, 0.1215) 78.12%
                );
            }

            &.disabled {
                cursor: not-allowed;
            }

            &:not(.active) a {
                color: var(--grey2);
                text-align: center;
                line-height: 20px;
            }
        }

        .VuePagination__pagination-item-prev-page,
        .VuePagination__pagination-item-next-page {
            width: max-content;
            // min-width: 100px;
            background: rgba(248, 209, 148, 0.1);
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

            &:not(.disabled):hover {
                background: rgba(248, 209, 148, 0.2);
            }
        }

        .VuePagination__pagination-item-prev-page {
            margin-right: auto;
        }

        .VuePagination__pagination-item-next-page {
            margin-left: auto;
        }

        .VuePagination__pagination-item.active a,
        .VuePagination__pagination-item-prev-page a,
        .VuePagination__pagination-item-next-page a {
            background: linear-gradient(
                180deg,
                #f89a02 0%,
                #c57705 46.35%,
                rgba(197, 119, 5, 0.81) 78.12%
            );
            -webkit-text-fill-color: transparent;
            -moz-background-clip: text;
            -webkit-background-clip: text;
            background-clip: text;
        }

        .VuePagination__pagination-item-prev-page a,
        .VuePagination__pagination-item-next-page a {
            display: flex;
            align-items: center;
        }

        .VuePagination__pagination-item-prev-page a span {
            margin-left: 0.5rem;
        }

        .VuePagination__pagination-item-next-page a span {
            margin-right: 0.5rem;
        }

        .VuePagination__pagination-item-prev-chunk,
        .VuePagination__pagination-item-next-chunk {
            display: none;
        }
    }
}

@media screen and (max-width: 992px) {
    footer [class*="main-wrapper"] > [class*="col-"] {
        margin-bottom: 3rem;
    }
}

@media screen and (max-width: 991px) {
    header
        nav.main-navigation
        #mobile-menu
        [class|="mobile-wrap"]
        [class*="main-links"]
        > ul
        li[class|="dropdown-parent"]
        svg {
        display: none !important;
    }

    header
        nav.main-navigation
        #mobile-menu
        [class|="mobile-wrap"]
        [class*="main-links"]
        > ul
        li[class|="dropdown-parent"]
        [class*="dropdown-inner"] {
        padding-left: 10px;

        span {
            font-size: 1.25rem;
            padding: 0;

            .service-desc {
                font-size: 1rem;
                color: var(--grey2);
            }
        }
    }
}

header [class|="wrap-header"][class*="padding-lg"] {
    padding: 1.25rem 4%;
}

@media screen and (min-width: 1024px) {
    header [class|="wrap-header"][class*="padding-lg"] {
        padding-left: 4%;
        padding-right: 4%;
    }
}

@media screen and (min-width: 1440px) {
    header [class|="wrap-header"][class*="padding-lg"] {
        padding-left: 135px;
        padding-right: 135px;
    }
}

@media screen and (max-width: 426px) {
    header [class|="top-header"][class*="padding-lg"],
    header [class|="wrap-header"][class*="padding-lg"] {
        padding-left: 0px;
        padding-right: 0px;
    }

    header nav.main-navigation #logo a.navbar-brand img {
        max-height: 65px !important;
    }

    header nav.main-navigation #main-menu .menu-holder + [class|="cta"],
    header nav.main-navigation #main-menu .menu-holder + [class|="menu-button"],
    header nav.main-navigation #main-menu .menu-holder + [class|="shortlinks"],
    header nav.main-navigation #top-menu .menu-holder + [class|="cta"],
    header nav.main-navigation #top-menu .menu-holder + [class|="menu-button"],
    header nav.main-navigation #top-menu .menu-holder + [class|="shortlinks"],
    header nav.top-navigation #main-menu .menu-holder + [class|="cta"],
    header nav.top-navigation #main-menu .menu-holder + [class|="menu-button"],
    header nav.top-navigation #main-menu .menu-holder + [class|="shortlinks"],
    header nav.top-navigation #top-menu .menu-holder + [class|="cta"],
    header nav.top-navigation #top-menu .menu-holder + [class|="menu-button"],
    header nav.top-navigation #top-menu .menu-holder + [class|="shortlinks"] {
        margin-left: 2rem;
    }
}

@media screen and (max-width: 414px) {
    header nav.main-navigation #main-menu [class|="cta"],
    header nav.main-navigation #top-menu [class|="cta"],
    header nav.top-navigation #main-menu [class|="cta"],
    header nav.top-navigation #top-menu [class|="cta"] {
        display: none;
    }

    header nav.main-navigation #mobile-menu [class|="mobile-wrap"] {
        padding-left: 4vw;
        padding-right: 4vw;
    }

    .shown_mobile_only {
        display: flex !important;
        margin-top: 1.5rem;
    }

    // .shown_mobile_only a:last-child {
    //     margin-left: 0.5rem;
    // }
}
</style>
