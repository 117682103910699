import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Axios from "axios";
import VueSocialSharing from 'vue-social-sharing';
import Pagination from 'vue-pagination-2';

// import JQuery from 'jquery'
// window.$ = JQuery

Vue.component('MyPagination', Pagination);
Vue.use(VueSocialSharing);

Vue.prototype.axios = Axios;
Axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
