var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
        _vm.currentSitePage == 'Home',
        ['Pay'].includes(_vm.currentSitePage),
        ['NotFound'].includes(_vm.currentSitePage),
    ]},[(['Home', 'Pay', 'NotFound'].includes(_vm.currentSitePage))?_vm._m(0):_vm._e(),(_vm.currentSitePage == 'NotFound')?_vm._m(1):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('Navbar')
},function (){var _vm=this,_c=_vm._self._c;return _c('NotFound')
}]

export { render, staticRenderFns }