<template>
    <header id="header" class="transparent--fixed container-fluid">
        <!--  padding-sm, padding-md, padding-lg  -->
        <div class="wrap-header--padding-lg">
            <nav class="main-navigation">
                <!---------------  Header Logo  --------------->
                <!--  class: height-sm, height-md, height-lg  -->
                <div id="logo" class="height-lg">
                    <router-link :to="{ name: 'Home' }" class="navbar-brand">
                        <img
                            src="@/assets/img/logos/logo-paylink.png"
                            alt="paylink logo"
                        />
                    </router-link>
                </div>
                <!---------------  END Header Logo  --------------->

                <!---------------  Main Menu START --------------->
                <!-- <div id="main-menu" aria-expanded="false" role="navigation"> -->

                <!-- <div class="cta">
                        <a
                            href="#download-app"
                            class="btn--big--round--border text-capitalize"
                            >Get started</a
                        >
                    </div> -->

                <!-- <div class="cta">
                        <a
                            href="https://cashwyre.io/register"
                            target="_blank"
                            rel="noopener noreferrer"
                            class="
                                btn--medium--round
                                bg_success
                                text-capitalize
                            "
                            >Signup</a
                        >
                    </div> -->

                <!-- <div class="menu-button" data-visible="lg">
                        <div class="icons">
                            <span class="bar bar-1"></span>
                            <span class="bar bar-2"></span>
                            <span class="bar bar-3"></span>
                        </div>
                    </div> -->

                <!-- </div> -->
                <!---------------  Main Menu END --------------->

                <!---------------  Mobile Menu START --------------->
                <!-- <div
                    id="mobile-menu"
                    data-visible="lg"
                    aria-expanded="false"
                    role="navigation"
                >
                    <div class="mobile-wrap">
                        <div class="main-links--border col-12">
                            <div class="cta d-none shown_mobile_only"> -->

                <!-- <a
                                    href="javascript:void(0)"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    class="
                                        btn--big--round--border
                                        text-capitalize
                                    "
                                    >Login</a
                                > -->

                <!-- <a
                                    href="https://cashwyre.io/register"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    class="
                                        btn--medium--round
                                        bg_success
                                        text-capitalize
                                    "
                                    >Signup</a
                                > -->

                <!-- </div>
                        </div>
                    </div>
                </div> -->
                <!---------------  Mobile Menu END --------------->
            </nav>
        </div>
    </header>
</template>

<script>
import $ from "jquery";
import "@/mixins";

export default {
    methods: {
        onScroll() {
            /*-------------------------------------------------*/
            /* =  Menu
            /*-------------------------------------------------*/
            var menuMobile = $("#mobile-menu"),
                mainMenu = $("#main-menu"),
                header = $("#header"),
                headerHeight = header.outerHeight(),
                headerStickySpace = 100;

            mainMenu.on("click", '[class*="menu-button"]', function () {
                $("body").toggleClass("no-scroll");

                // var mainMenuItem = $(e.currentTarget);

                if (menuMobile.attr("aria-expanded") === "true") {
                    menuMobile.attr("aria-expanded", "false");
                    $(this).parent().attr("aria-expanded", "false");
                    menuMobile
                        .find('[class*="mobile-submenu"]')
                        .removeClass("active");
                } else {
                    menuMobile.attr("aria-expanded", "true");
                    $(this).parent().attr("aria-expanded", "true");
                }
            });

            menuMobile.on(
                "click",
                'li[class*="dropdown-parent"]',
                function (e) {
                    var menuItem = $(e.currentTarget);

                    // fixed submenu mobile menu
                    if (menuItem.attr("aria-expanded") === undefined) {
                        var idDropdown = menuItem.data("dropdown");
                        menuMobile.find("#" + idDropdown).toggleClass("active");
                    } else {
                        // dropdown mobile menu
                        if (menuItem.attr("aria-expanded") === "true") {
                            $(this).attr("aria-expanded", "false");
                        } else {
                            $(this).attr("aria-expanded", "true");
                        }
                    }
                }
            );

            menuMobile.on("click", "#backSubmenu", function () {
                $(this).parent().removeClass("active");
            });

            /*-------------------------------------------------*/
            /* =  Sticky menu
            /*-------------------------------------------------*/
            $(window).on("scroll", function () {
                var scroll = $(window).scrollTop();

                if (scroll >= headerHeight + headerStickySpace) {
                    header.addClass("moove");
                } else {
                    header.removeClass("moove");
                }
            });
        },
    },

    mounted() {
        this.onScroll();
    },
};
</script>

<style lang="scss" scoped>
// header
//     nav.main-navigation
//     #main-menu
//     .menu-holder
//     ul
//     li[class*="dropdown-parent"][class*="bg"][class*="items"]
//     [class|="dropdown-inner"]
//     ul
//     li:hover {
//     .service {
//         color: var(--orangeDark);
//     }
// }

header nav.main-navigation #main-menu .menu-holder ul {
    margin-bottom: 0;
}

header
    nav.main-navigation
    #main-menu
    .menu-holder
    ul
    li[class*="dropdown-parent"]
    [class|="dropdown-inner"]
    ul {
    min-width: 350px;
    // background-color: var(--black2) !important;
    background-color: rgba(22, 23, 25, 0.935) !important;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    box-shadow: 0 0.125rem 0.125rem rgb(0 0 0 / 15%);

    li {
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 2px;

        &:hover {
            background: transparent !important;

            // a {
            //     .wallet-icon,
            //     .contact-icon {
            //         fill: var(--black2);
            //     }
            // }

            .service {
                color: var(--orangeDark);
            }

            // .service-desc {
            //     color: var(--black2);
            // }
        }

        a {
            display: flex;
            align-items: center;

            &.active {
                .service {
                    color: var(--orangeDark);
                }
            }

            svg {
                min-width: 32px;
                min-height: 32px;
                margin-right: 10px;
            }

            .wallet-icon {
                fill: var(--white);
            }

            .payment-icon {
                fill: var(--orangeDark);
            }

            .about-icon {
                fill: var(--white);

                circle {
                    fill: var(--orangeDark);
                }
            }

            .contact-icon {
                fill: var(--white);
            }

            .faq-icon {
                fill: var(--orangeDark);
            }

            .service {
                display: flex;
                -ms-flex-direction: column;
                flex-direction: column;
                color: var(--white);
            }

            .service-desc {
                font-size: 13px;
                color: var(--grey2);
                margin-top: 5px;
                line-height: 18px;
            }
        }
    }
}

header
    nav.main-navigation
    #mobile-menu
    [class|="mobile-wrap"]
    [class*="main-links"]
    > ul
    li
    a {
    &.active {
        color: var(--orangeDark);
    }

    &:hover,
    &.active {
        .service {
            color: var(--orangeDark);
        }
    }
}
</style>
