<template>
    <div id="footer-top" class="row main-wrapper">
        <div class="col-12 col-md-12 col-lg-6">
            <div class="info-footer">
                <div class="logo-footer">
                    <!-- -small, -medium, -big -->
                    <div class="image--big mb-20">
                        <router-link :to="{ name: 'Home' }">
                            <img
                                src="@/assets/img/logos/logo-paylink.png"
                                alt="paylink logo"
                                style="opacity: 1"
                            />
                        </router-link>
                    </div>
                </div>
                <p class="description mb-0">
                    Cashwyre is simplifying crowdfunding through Paylink.
                    Cashwyre Paylink offers a very simple and secure way to
                    raise funds for your projects globally (not limited to your
                    country of residence or local currency).
                </p>
            </div>
        </div>

        <div class="col-12 col-md-6 col-lg-3">
            <div class="list-element--text">
                <!-- <h6>Support</h6> -->
                <h6>Quick Link</h6>
                <ul>
                    <li>
                        <router-link :to="{ name: 'Home' }"
                            >All Paylinks</router-link
                        >
                    </li>
                    <!-- <li>
                        <router-link :to="{ name: 'FAQs' }">FAQs</router-link>
                    </li> -->
                </ul>
            </div>
        </div>

        <div class="col-12 col-md-6 col-lg-3">
            <div class="list-element--text">
                <h6>Need to make an enquiry?</h6>

                <p class="mb-10">
                    <a
                        href="mailto:paylink@cashwyre.io"
                        target="_blank"
                        rel="noopener noreferrer"
                        ><i class="fa fa-envelope"></i> paylink@cashwyre.io</a
                    >
                </p>

                <div class="social-element--small">
                    <ul>
                        <li>
                            <a
                                href="https://www.facebook.com/profile.php?id=100086763845181"
                                target="_blank"
                                rel="noopener noreferrer"
                                ><i class="fab fa-facebook-f"></i
                            ></a>
                        </li>
                        <!-- <li>
                            <a
                                href="https://www.linkedin.com/cashwyre/"
                                target="_blank"
                                rel="noopener noreferrer"
                                ><i class="fab fa-linkedin-in"></i
                            ></a>
                        </li> -->
                        <li>
                            <a
                                href="https://www.instagram.com/p/CkqCbqEDCca/?igshid=YmMyMTA2M2Y="
                                target="_blank"
                                rel="noopener noreferrer"
                                ><i class="fab fa-instagram"></i
                            ></a>
                        </li>
                        <li>
                            <a
                                href="https://twitter.com/Cashwyre_HQ?t=WqQPZZ4u2S9bFtrXjiv9FQ&s=09"
                                target="_blank"
                                rel="noopener noreferrer"
                                ><i class="fab fa-twitter"></i
                            ></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#footer-top {
    padding-top: 50px;
    padding-bottom: 50px;

    h6 {
        font-size: 1.125rem;
        color: var(--white);
    }

    p {
        font-size: 15px;
        color: var(--grey2);
    }

    a {
        font-size: 15px;
        color: var(--grey2);

        &,
        i {
            -o-transition: 0.4s ease;
            -moz-transition: 0.4s ease;
            -webkit-transition: 0.4s ease;
            transition: 0.4s ease;
        }

        &:hover {
            color: var(--offWhite);
            opacity: 1;
        }
    }

    .info-footer {
        & .description {
            max-width: 85%;

            @media screen and (max-width: 768px) {
                max-width: 100%;
            }

            a {
                margin-top: 8px;
            }
        }
    }

    .fa-map-marker-alt,
    .fa-envelope,
    .fa-phone {
        font-size: 0.75rem;
        margin-right: 0.125rem;
    }

    .fa-phone {
        transform: rotate(90deg);
    }

    [class|="social-element"] {
        li a i {
            color: var(--orangeDark);

            &:hover {
                color: var(--offWhite);
            }
        }
    }
}
</style>
