<template>
    <div class="single-category" v-if="paylink">
        <router-link :to="{ name: 'Pay', query: { reference: paylink.code } }">
            <div class="image-wrapper" :title="paylink.title">
                <img class="image" :src="paylink.logo" :alt="paylink.title" />

                <div class="category-info">
                    <h3 class="category-title">
                        {{ paylink.title }}
                    </h3>
                    <time :datetime="removeFormatDate(paylink.createdOn)">{{
                        formateDateByName(paylink.createdOn)
                    }}</time>
                </div>
            </div>

            <ProgressBar
                :totalDonationVolume="paylink.totalDonationVolume"
                :targetAmountInAsset="paylink.targetAmountInAsset"
                :barWidth="80 + '%'"
            />

            <p class="category-description">
                {{ paylink.message }}
            </p>

            <div class="donate-button">
                <button class="button">
                    Donate
                    <svg
                        width="11"
                        height="11"
                        viewBox="0 0 11 11"
                        fill="none"
                        class="icon"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1.81543 9.26287L10.1379 0.94043M10.1379 0.94043H1.81543M10.1379 0.94043V9.26287"
                            stroke="url(#paint0_linear_2914_4314)"
                            stroke-width="1.66782"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <defs>
                            <linearGradient
                                id="paint0_linear_2914_4314"
                                x1="5.97665"
                                y1="0.94043"
                                x2="5.97665"
                                y2="9.26287"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stop-color="#F89A02" />
                                <stop offset="0.463542" stop-color="#C57705" />
                                <stop
                                    offset="0.78125"
                                    stop-color="#C57705"
                                    stop-opacity="0.81"
                                />
                            </linearGradient>
                        </defs>
                    </svg>

                    <!-- <svg
                        class="icon"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                    >
                        <path
                            d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"
                        />
                    </svg> -->
                </button>
            </div>
        </router-link>
    </div>
</template>

<script>
import "@/mixins";
import ProgressBar from "./ProgressBar.vue";

export default {
    props: ["paylink"],

    components: {
        ProgressBar,
    },
};
</script>

<style lang="scss" scoped>
.single-category {
    .image-wrapper {
        position: relative;
        height: 250px;
        margin-bottom: 30px;
        // background: var(--grey);

        .image {
            height: 100%;
            width: 100%;
            // background-size: cover;
            // background-position: top;
            // background-repeat: no-repeat;
            -o-object-fit: cover;
            object-fit: cover;
            -o-object-position: center;
            object-position: center;
        }

        .category-info {
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
            font-size: 0.875rem;
            // background: linear-gradient(180deg, #f89a02 0%, #c57705 46.35%);
            background: linear-gradient(
                -45deg,
                rgba(248, 154, 2, 0.65),
                rgba(197, 120, 5, 0.75)
            );
            backdrop-filter: blur(40px);
            -webkit-backdrop-filter: blur(40px);
            color: var(--white);
            padding: 15px 20px;

            .category-title {
                font-size: 0.875rem;
                margin-bottom: 0.5rem;
                text-transform: capitalize;
            }
        }
    }

    .category-description {
        color: var(--grey2);
        margin-top: 25px;
        margin-bottom: 30px;
    }

    .donate-button {
        .button {
            position: relative;
            font-weight: 500;
            font-size: 16px;
            background: linear-gradient(
                180deg,
                #f89a02 0%,
                #c57705 46.35%,
                rgba(197, 119, 5, 0.81) 78.12%
            );
            -webkit-text-fill-color: transparent;
            -moz-background-clip: text;
            -webkit-background-clip: text;
            background-clip: text;
            color: var(--orangeDark);
            padding: 0;
            line-height: 24px;
            cursor: pointer;

            .icon {
                margin-left: 0.5rem;
            }

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                height: 1px;
                background: linear-gradient(
                    180deg,
                    #f89a02 0%,
                    #c57705 46.35%,
                    rgba(197, 119, 5, 0.81) 78.12%
                );
            }

            &:hover::after {
                -o-animation: slideIn 0.3s linear forwards;
                -moz-animation: slideIn 0.3s linear forwards;
                -webkit-animation: slideIn 0.3s linear forwards;
                animation: slideIn 0.2s linear forwards;
            }

            @keyframes slideIn {
                from {
                    width: 0;
                }
                to {
                    width: 100%;
                }
            }
        }
    }
}
</style>