<template>
    <div
        :class="[
            currentSitePage == 'Home',
            ['Pay'].includes(currentSitePage),
            ['NotFound'].includes(currentSitePage),
        ]"
    >
        <!-- <Navbar :currentSitePage="currentSitePage" /> -->

        <Navbar
            v-if="['Home', 'Pay', 'NotFound'].includes(currentSitePage)"
            v-once
        />

        <!-- <Navbar2 v-if="currentSitePage == 'NotFound'" v-once /> -->

        <!-- <HomeBanner1 v-if="currentSitePage == 'Home'" v-once /> -->

        <!-- <PageHeader
            v-if="
                ['WalletServices', 'PaymentServices'].includes(currentSitePage)
            "
            v-once
        /> -->

        <!-- <PayBanner v-if="['Contact'].includes(currentSitePage)" v-once /> -->

        <NotFound v-if="currentSitePage == 'NotFound'" v-once />
    </div>
</template>
<script>
import "@/mixins";
import Navbar from "./TheNavbar.vue";
// import Navbar2 from "./TheNavbar2.vue";
// import HomeBanner1 from "../banners/HomeBanner1.vue";
// import PageHeader from "../banners/PageHeader.vue";
// import PayBanner from "../banners/PayBanner.vue";
import NotFound from "../banners/NotFound.vue";

export default {
    components: {
        Navbar,
        // Navbar2,
        // HomeBanner1,
        // PageHeader,
        // PayBanner,
        NotFound,
    },

    data() {
        return {};
    },
};
</script>

<style scoped>
</style>
