<template>
    <transition name="fade">
        <div class="page_processing_modal" v-if="alertModalStatus">
            <div class="page_processing_card">
                <div class="message_stat">
                    <h4>Delivery Status</h4>
                </div>
                <p :class="alertModalType == 'success' ? '' : 'text-danger'">
                    {{ alertModalMessage }}
                </p>
                <button class="btn--medium--round" @click="closeModal">
                    Okay
                </button>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
export default {
    computed: {
        ...mapGetters(["alertModalMessage", "alertModalType"]),
        ...mapState(["alertModalStatus"]),
    },

    methods: {
        ...mapActions(["setAlertModalStatus"]),

        closeModal: function () {
            this.setAlertModalStatus({
                type: "",
                status: false,
                message: "",
            });
        },
    },
};
</script>

<style scoped>
.page_processing_modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(22, 23, 25, 0.5);
    -webkit-backdrop-filter: blur(1.5px);
    backdrop-filter: blur(1.5px);
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 69990;
    -o-transition: 0.8s ease;
    -moz-transition: 0.8s ease;
    -webkit-transition: 0.8s ease;
    transition: 0.8s ease;
}

.page_processing_card {
    background: rgb(255, 255, 255);
    width: 320px;
    height: auto;
    padding: 20px;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    box-shadow: 3px 6px 10px 0 rgb(0 102 245 / 7%);
    text-align: center;
}

.page_processing_card h4 {
    font-weight: 700;
    font-size: 1rem;
    margin-bottom: 1rem;
}

.page_processing_card p {
    font-size: 1.125rem;
    margin-bottom: 1.625rem;
}

.page_processing_card button {
    width: 35%;
    font-weight: bold;
    cursor: pointer;
    background: var(--orangeLight);
    color: #fff;
}

/* .page_processing_card img {
    width: 50px;
} */

.message_stat {
    /* border-radius: 50%; */
    padding: 13px 15px 10px;
}

/* .message_stat .fa {
    font-size: 20px;
} */

.text-danger {
    color: var(--errorColor);
}

@media screen and (max-width: 350px) {
    .page_processing_card {
        width: 95%;
        margin: auto;
    }
}
</style>
