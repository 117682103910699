import axios from "axios";
import $ from "jquery";

// ########################           PAGE SETTINGS        #################################
export const setCurrentPage = ({ commit }, payload) => {
  commit("CURRENT_SITE_PAGE", payload);

  if (document.querySelector(".navbar-placeholder")) {
    if ($("#header_hero").hasClass("greyBackground")) {
      $(".navbar-menu").removeClass("is-active");
      $(".custom-burger").removeClass("is-active");
      $(".icon-box-toggle").removeClass("active");
    }
  }
};

export const setBannerSettings = ({ commit }, payload) => {
  commit("BANNER_SETTINGS", payload);
};


// ########################           LOADERS        #################################
export const setActionLoading = ({ commit }, status) => {
  commit("SET_ACTION_LOADING", status);
};

export const setPageProcessing = ({ commit }, status) => {
  commit("SET_PAGE_PROCESSING", status);
};

export const setPageLoading = ({ commit }, status) => {
  commit("SET_PAGE_LOADING", status);
};


// ########################           MODALS        #################################
export const setAlertModalStatus = ({ commit }, status) => {
  commit("SET_ALERT_MODAL_STATUS", status);
};

export const toggleIsModalActive = ({ commit }, status) => {
  commit("TOGGLE_IS_MODAL_ACTIVE", status);
};

export const toggleIsShareModalActive = ({ commit }) => {
  commit("TOGGLE_IS_SHARE_MODAL_ACTIVE");
};


// ########################          SERVICES        #################################
export const savePaylinkCategories = async ({ commit, state }) => {
  const url = `${state.cashwyreURL}/v1.0/Paylink/getPaylinkCategories`;

  // let options = {
  //   method: 'POST',
  //   headers: {
  //     'Access-Control-Allow-Origin': '*',
  //     'Content-Type': 'application/json',
  //   }
  // };

  await axios.post(url)
    .then(res => {
      // console.log(res);

      if (res.data.data) {
        // console.log(res.data.data);
        commit("SAVE_PAYLINK_CATEGORIES", res.data.data);
      }
    }).catch(err => {
      console.log(err);
    })
};

export const savePaylinkCategoriesInfo = async ({ commit, state, dispatch }) => {
  const url = `${state.cashwyreURL}/v1.0/Paylink/getPaylinkCategoriesInfo`;

  const data = {
    "appId": state.AppId,
    "requestId": state.RequestId,
    "category": ""
  };

  let payload;

  await axios.post(url, data)
    .then(res => {
      if (res.data.data) {
        commit("SAVE_PAYLINK_CATEGORIES_INFO", res.data.data);
      }
    }).catch(err => {
      console.log(err);

      payload = {
        status: true,
        type: "error",
        message: err.message,
      };

      // console.log(payload);

      dispatch('setAlertModalStatus', payload)
    })
};

export const savePaylinkInfo = async ({ commit, state, dispatch }, code) => {
  const url = `${state.cashwyreURL}/v1.0/Paylink/getPaylinkInfo`;

  const data = {
    "appId": state.AppId,
    "requestId": state.RequestId,
    "code": code.reference
  };

  let payload;

  await axios.post(url, data)
    .then(res => {
      if (res.data.data) {
        // console.log(res.data);

        commit("SAVE_PAYLINK__INFO", res.data.data);
      }
    }).catch(err => {
      console.log(err);

      payload = {
        status: true,
        type: "error",
        message: err.message,
      };

      // console.log(payload);

      dispatch('setAlertModalStatus', payload)
    })
};

export const saveAssetRate = async ({ commit, state }, assetType) => {
  const url = `${state.cashwyreURL}/v1.0/Paylink/getAssetRate`;

  const data = {
    "appId": state.AppId,
    "requestId": state.RequestId,
    "assetType": assetType
  };

  await axios.post(url, data)
    .then(res => {
      if (res.data.data) {
        // console.log(res.data);

        commit("SAVE_ASSET_RATE", res.data.data);
      }
    }).catch(err => {
      console.log(err);
    })
};

export const saveBitcoinRate = async ({ dispatch, commit, state }) => {
  await dispatch('saveAssetRate', 'bitcoin');
  if (state.assetRate) {
    const bitcoinRate = state.assetRate;
    commit("SAVE_BITCOIN_RATE", bitcoinRate);
  }
};

export const saveReceiveCryptoAssetOptions = async ({ commit, state }, assetType) => {
  const url = `${state.cashwyreURL}/v1.0/Paylink/getReceiveCryptoAssetOptions`;

  const data = {
    "appId": state.AppId,
    "requestId": state.RequestId,
    "assetType": assetType
  };

  await axios.post(url, data)
    .then(res => {
      if (res.data.data) {
        // console.log(res.data);

        commit("SAVE_ASSET_OPTIONS", res.data.data);
      }
    }).catch(err => {
      console.log(err);
    })
};

// export const getAuthentication = async ({ state }) => {
//   const url = `${state.cashwyreURL}/v1.0/Authenticate/authenticateEndpoints`;
//   let data = {
//     AppId: state.AppId,
//     RequestId: state.RequestId,
//   };

//   let result = await axios.post(url, data);

//   try {
//     if (result.data.success) {
//       // commit("SAVE_INVESTMENT", result.data.data);
//       console.log(result.data.data);
//     }
//   } catch (error) {
//     console.log(error);
//   }
// };

// export const getFeedbacks = ({ commit, state }, payload) => {
//   const url = `${state.cashwyreURL}/v1.0/Feedback/getFeedbacks`;

//   var data = {
//     AppId: state.AppId,
//     RequestId: state.RequestId + new Date(),
//     UserCode: payload.userCode,
//     FromDate: payload.from,
//     ToDate: payload.to,
//     PageNumber: payload.page,
//     PageSize: 20,
//   };

//   axios
//     .post(url, data)
//     .then((res) => {
//       if (res.data.success) {
//         let responseData = res.data.data;
//         // console.log(responseData);
//         commit("SAVE_FEEDBACKS", responseData);
//       }
//     })
//     .catch((err) => {
//       console.log(err);
//     });
// };
