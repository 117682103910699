/* eslint-disable */
export default {
  currentSitePage: "",
  bannerSettings: {
    title: "",
    description: "",
    buttonUrl: null,
    prevLinkText: null,
    currentLinkText: null,
    imageUrl: null,
  },

  // LOADING
  pageProcessing: false,

  pageLoading: {
    status: false,
    message: "",
    payload: null,
  },

  actionProcessing: false,

  // MODALS
  alertModalStatus: false,
  alertModalType: "",
  alertModalMessage: "",

  isModalActive: false,
  isShareModalActive: false,

  // GLOBAL PROPERTIES
  AppId: String(process.env.VUE_APP_APP_ID),
  RequestId: String(process.env.VUE_APP_REQUEST_ID) + Math.floor(Math.random() * 10),

  cashwyreURL: "https://cashwyreservice.azurewebsites.net/api",
  countryFlagUrl: "https://countryflagsapi.com/png",

  paylinkCategories: null,
  paylinkCategoriesInfo: null,
  paylinkInfo: null,

  assetRate: null,
  bitcoinRate: null,

  assetOptions: null,
};
