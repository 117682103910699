var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"all-categories"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"inner-wrapper"},[_c('div',{staticClass:"header"},[_vm._m(0),_c('div',{staticClass:"category-buttons"},[_c('button',{staticClass:"button active-button",attrs:{"aria-label":"all"},on:{"click":function($event){$event.preventDefault();_vm.toggleCategory(
                                        $event,
                                        (_vm.categoryCode = 'all')
                                    )}}},[_vm._v(" all ")]),_vm._l((_vm.allPaylinkCategories),function(category){return _c('button',{key:category.code,staticClass:"button",attrs:{"aria-label":category.name},on:{"click":function($event){$event.preventDefault();return _vm.toggleCategory($event, category.code)}}},[_vm._v(" "+_vm._s(category.name)+" ")])})],2)]),(_vm.allCategories && !_vm.isLoading)?_c('div',[_c('div',{staticClass:"category-contents-wrapper"},[(
                                    _vm.displayedPaylinks &&
                                    _vm.displayedPaylinks.length > 0
                                )?_c('div',{staticClass:"category-contents"},_vm._l((_vm.displayedPaylinks),function(paylink){return _c('CategorySingle',{key:paylink.code,attrs:{"paylink":paylink}})}),1):_c('div',{staticClass:"no-paylinks"},[_c('h4',[_vm._v(" No paylink available for this category ")])])]),_c('div',{staticClass:"pagination-wrapper"},[_c('hr',{staticClass:"divider"}),(
                                    _vm.displayedPaylinks &&
                                    _vm.isCategoryAll &&
                                    _vm.totalPaylinksAll > _vm.pageSize
                                )?_c('div',{attrs:{"id":"pagination"}},[_c('MyPagination',{attrs:{"records":_vm.totalPaylinksAll,"per-page":_vm.pageSize},on:{"paginate":_vm.paginateAllPaylinks},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e(),(
                                    _vm.displayedPaylinks &&
                                    !_vm.isCategoryAll &&
                                    _vm.totalPaylinksCategory > _vm.pageSize
                                )?_c('div',{attrs:{"id":"pagination"}},[_c('MyPagination',{attrs:{"records":_vm.totalPaylinksCategory,"per-page":_vm.pageSize},on:{"paginate":_vm.paginateCategoryPaylinks},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e()])]):_c('div',{staticClass:"loading-wrapper"},[_c('LoadingComponent'),_c('hr',{staticClass:"divider"})],1),_c('TheFooter')],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sectiontitle-element"},[_c('h1',{staticClass:"banner-title",attrs:{"data-aos":"fade-in"}},[_vm._v(" Cashwyre Paylink ")])])
}]

export { render, staticRenderFns }