import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/HomeView.vue";
// import $ from "jquery";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: {
            transition: "fade-in-down",
            title: "Simplifying Crowd-Funding | Cashwyre Paylink",
            metaTags: [
                {
                    name: "description",
                    content:
                        "Cashwyre is simplifying crowdfunding through Paylink. Cashwyre Paylink offers a very simple and secure way to raise funds for your projects globally.",
                },
                {
                    name: "keywords",
                    content:
                        "Cashwyre, Paylink, Bitcoin, BTC, Fintech, USDT, USDC, Border-less, Cross-border Payment, Crowd-Funding",
                },
                {
                    property: "og:description",
                    content:
                        "Cashwyre is simplifying crowdfunding through Paylink. Cashwyre Paylink offers a very simple and secure way to raise funds for your projects globally.",
                },
            ],
        },
    },
    {
        path: "/pay",
        name: "Pay",
        component: () => import("../views/PayView.vue"),
        meta: {
            transition: "fade-in-down",
            title: "Donate Freely | Cashwyre Paylink",
            metaTags: [
                {
                    name: "description",
                    content:
                        "Cashwyre is simplifying crowdfunding through Paylink. Cashwyre Paylink offers a very simple and secure way to raise funds for your projects globally.",
                },
                {
                    name: "keywords",
                    content:
                        "Cashwyre, Paylink, Bitcoin, BTC, Fintech, USDT, USDC, Border-less, Cross-border Payment, Crowd-Funding",
                },
                {
                    property: "og:description",
                    content:
                        "Cashwyre is simplifying crowdfunding through Paylink. Cashwyre Paylink offers a very simple and secure way to raise funds for your projects globally.",
                },
            ],
        },
    },
    {
        path: "*",
        name: "NotFound",
        component: () => import("../views/FourZeroFour.vue"),
        meta: {
            transition: "fade-in-down",
            title: "404 - Page Not Found | Cashwyre Paylink",
            metaTags: [
                {
                    name: "description",
                    content:
                        "Cashwyre is simplifying crowdfunding through Paylink. Cashwyre Paylink offers a very simple and secure way to raise funds for your projects globally.",
                },
                {
                    name: "keywords",
                    content:
                        "Cashwyre, Paylink, Bitcoin, BTC, Fintech, USDT, USDC, Border-less, Cross-border Payment, Crowd-Funding",
                },
                {
                    property: "og:description",
                    content:
                        "Cashwyre is simplifying crowdfunding through Paylink. Cashwyre Paylink offers a very simple and secure way to raise funds for your projects globally.",
                },
            ],
        },
    },
];

const router = new VueRouter({
    mode: "history",
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {
                x: 0,
                y: 0,
            };
        }
    },
    base: process.env.BASE_URL,
    routes,

    linkActiveClass: "active",
    linkExactActiveClass: "exact-active",
});

const DEFAULT_TITLE = "Simplifying Crowd-Funding | Cashwyre Paylink";

router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });

    // var menuMobile = $('#mobile-menu'),
    //     mainMenu = $('#main-menu');

    // $('body').removeClass('no-scroll');

    // if (menuMobile.attr('aria-expanded') === 'true') {
    //     menuMobile.attr('aria-expanded', 'false');
    //     mainMenu.attr('aria-expanded', 'false');
    //     menuMobile.find('[class*="mobile-submenu"]').removeClass('active');
    // }
});

export default router;
