<template>
    <div class="all-categories">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="inner-wrapper">
                        <div class="header">
                            <div class="sectiontitle-element">
                                <h1 data-aos="fade-in" class="banner-title">
                                    Cashwyre Paylink
                                </h1>
                            </div>

                            <div class="category-buttons">
                                <button
                                    class="button active-button"
                                    @click.prevent="
                                        toggleCategory(
                                            $event,
                                            (categoryCode = 'all')
                                        )
                                    "
                                    aria-label="all"
                                >
                                    all
                                </button>
                                <button
                                    v-for="category in allPaylinkCategories"
                                    :key="category.code"
                                    class="button"
                                    @click.prevent="
                                        toggleCategory($event, category.code)
                                    "
                                    :aria-label="category.name"
                                >
                                    {{ category.name }}
                                </button>
                            </div>
                        </div>

                        <div v-if="allCategories && !isLoading">
                            <div class="category-contents-wrapper">
                                <!-- <h2 class="category-type">
                                    {{ categoryInfo.name }}
                                </h2> -->

                                <div
                                    class="category-contents"
                                    v-if="
                                        displayedPaylinks &&
                                        displayedPaylinks.length > 0
                                    "
                                >
                                    <CategorySingle
                                        v-for="paylink in displayedPaylinks"
                                        :key="paylink.code"
                                        :paylink="paylink"
                                    />
                                </div>

                                <div class="no-paylinks" v-else>
                                    <h4>
                                        No paylink available for this category
                                    </h4>
                                </div>
                            </div>

                            <div class="pagination-wrapper">
                                <hr class="divider" />

                                <div
                                    id="pagination"
                                    v-if="
                                        displayedPaylinks &&
                                        isCategoryAll &&
                                        totalPaylinksAll > pageSize
                                    "
                                >
                                    <MyPagination
                                        v-model="page"
                                        :records="totalPaylinksAll"
                                        :per-page="pageSize"
                                        @paginate="paginateAllPaylinks"
                                    />
                                </div>

                                <div
                                    id="pagination"
                                    v-if="
                                        displayedPaylinks &&
                                        !isCategoryAll &&
                                        totalPaylinksCategory > pageSize
                                    "
                                >
                                    <MyPagination
                                        v-model="page"
                                        :records="totalPaylinksCategory"
                                        :per-page="pageSize"
                                        @paginate="paginateCategoryPaylinks"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="loading-wrapper" v-else>
                            <LoadingComponent />
                            <hr class="divider" />
                        </div>

                        <TheFooter />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CategorySingle from "./CategorySingle.vue";
import LoadingComponent from "../modal/LoadingComponent.vue";
import TheFooter from "@/components/footer/TheFooter.vue";

import $ from "jquery";

export default {
    components: {
        CategorySingle,
        LoadingComponent,
        TheFooter,
    },

    computed: {
        ...mapGetters([
            "getPaylinkCategories",
            "getPaylinkCategoriesInfo",
            "alertModalMessage",
        ]),

        getAlertModalMessage() {
            let alert = null;

            if (this.alertModalMessage) {
                // console.log(this.alertModalMessage);
                alert = this.alertModalMessage;
            }
            return alert;
        },

        allCategories() {
            let categories = [];

            if (
                this.getPaylinkCategories &&
                this.getPaylinkCategories.length > 0
            ) {
                categories = this.getPaylinkCategories;
                // console.log(categories);
            }

            return categories;
        },

        allPaylinkCategoriesInfo() {
            let categoriesInfo = [];

            if (
                this.getPaylinkCategoriesInfo &&
                this.getPaylinkCategoriesInfo.length > 0
            ) {
                categoriesInfo = this.getPaylinkCategoriesInfo;
                // console.log(categoriesInfo);
            }

            return categoriesInfo;
        },
    },

    data() {
        return {
            allPaylinkCategories: null,
            allPaylinkInfo: null,

            allPaylinks: null,
            displayedPaylinks: null,

            isLoading: true,
            isCategoryAll: true,
            alertModalMessageError: null,

            // ------ pagination ------
            page: null,
            pageSize: 6,
            // totalPages: null,
            totalPaylinksAll: null,
            totalPaylinksCategory: null,
            categoryCode: null,
        };
    },

    methods: {
        ...mapActions(["savePaylinkCategories", "savePaylinkCategoriesInfo"]),

        toggleCategory(event, categoryCode) {
            const categoryButton = event.target;
            const allCategoryButtons = document.querySelectorAll(
                ".category-buttons .button"
            );

            if (categoryButton.classList.contains("active-button")) {
                return;
            } else {
                allCategoryButtons.forEach((button) => {
                    button.classList.remove("active-button");
                });
                categoryButton.classList.add("active-button");

                if (categoryCode === "all") {
                    this.isCategoryAll = true;
                    this.page = 1;

                    this.paginateAllPaylinks();
                } else {
                    this.categoryCode = categoryCode;
                    this.isCategoryAll = false;
                    this.page = 1;

                    this.paginateCategoryPaylinks();
                }

                // console.log(
                //     this.displayedPaylinks,
                //     categoryCode,
                //     this.totalPaylinksAll,
                //     this.totalPaylinksCategory
                // );
            }

            // console.log(event, categoryButton, categoryCode);
        },

        // ------ pagination ------
        getAllPaylinks() {
            // console.log(this.displayedPaylinks);

            this.isLoading = true;

            setTimeout(() => {
                this.isLoading = false;
                this.changePaginationNavTexts();
            }, 400);

            return (this.displayedPaylinks = this.displayedPaylinks.slice(
                (this.page - 1) * this.pageSize,
                this.page * this.pageSize
            ));
        },

        paginateAllPaylinks() {
            let paylinks = [];

            this.allPaylinkInfo.map((info) => {
                info.paylinks.forEach((paylink) => {
                    paylinks.push(paylink);
                });
            });

            this.allPaylinks = paylinks;
            // this.pageSize = Math.ceil(this.totalPaylinks / 1.65);
            // this.totalPages = Math.ceil(paylinks.length / this.pageSize);

            this.displayedPaylinks = this.allPaylinks;
            this.totalPaylinksAll = this.allPaylinks.length;

            this.getAllPaylinks();
        },

        paginateCategoryPaylinks() {
            let paylinks = [];

            this.allPaylinkInfo.map((info) => {
                info.paylinks.forEach((paylink) => {
                    paylinks.push(paylink);
                });
            });

            this.allPaylinks = paylinks.filter((category) => {
                return category.category === this.categoryCode;
            });

            this.displayedPaylinks = this.allPaylinks;
            this.totalPaylinksCategory = this.allPaylinks.length;

            this.getAllPaylinks();
        },

        changePaginationNavTexts() {
            setTimeout(() => {
                if (this.allPaylinkInfo) {
                    $(
                        "#pagination .VuePagination__pagination .VuePagination__pagination-item-prev-page"
                    ).html(`
                        <a class="page-link">
                            <i class="fa fa-arrow-left icon"></i>
                            <span>Previous</span>
                        </a>
                    `);

                    $(
                        "#pagination .VuePagination__pagination .VuePagination__pagination-item-next-page"
                    ).html(`
                        <a class="page-link">
                            <span>Next</span>
                            <i class="fa fa-arrow-right icon"></i>
                        </a>
                    `);
                }
            }, 1000);
        },
    },

    mounted() {
        this.savePaylinkCategories();
        this.savePaylinkCategoriesInfo();
    },

    watch: {
        allCategories() {
            this.allPaylinkCategories = this.allCategories;
            // console.log(this.allPaylinkInfo, this.allPaylinkCategories);

            if (
                !this.allPaylinkCategories &&
                this.alertModalMessageError.message
            ) {
                this.isLoading = false;
            }
        },

        allPaylinkCategoriesInfo() {
            this.allPaylinkInfo = this.allPaylinkCategoriesInfo;
            // console.log(this.allPaylinkInfo, this.allPaylinkCategories);

            if (this.allPaylinkInfo) {
                this.page = 1;
                this.paginateAllPaylinks();
            }

            this.isLoading = false;
            this.changePaginationNavTexts();
        },

        getAlertModalMessage() {
            this.alertModalMessageError = this.getAlertModalMessage;
            this.isLoading = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.all-categories {
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
        display: none;
    }

    .inner-wrapper {
        max-width: 1070px;
        margin: auto;
        background: var(--black1);

        .header {
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            background: var(--black1);
            padding-top: 130px;
            z-index: 4;
        }

        .sectiontitle-element {
            .banner-title {
                font-size: 1.625rem;
                color: var(--orangeDark);
                letter-spacing: 1px;
            }
        }

        .category-buttons {
            width: 100%;
            display: -ms-grid;
            display: -moz-grid;
            display: grid;
            // grid-template-columns: minmax(60px, auto);
            grid-auto-flow: column;
            gap: 10px 30px;
            padding-top: 40px;
            padding-bottom: 35px;
            overflow-x: auto;

            &::-webkit-scrollbar {
                width: 8px;
                // display: none;
            }

            .button {
                min-width: max-content;
                font-size: 15px;
                font-weight: 700;
                color: var(--offOrange);
                padding: 0.675rem 1rem;
                border: 1px solid var(--offOrange);
                border-radius: 8px;
                outline: none;
                line-height: 24px;
                text-transform: capitalize;
                text-align: center;
                cursor: pointer;

                &:hover {
                    color: var(--orangeDark);
                    border-color: var(--orangeDark);
                }

                &.active-button {
                    font-size: 18px;
                    background: linear-gradient(
                        180deg,
                        #f89a02 0%,
                        #c57705 46.35%,
                        rgba(197, 119, 5, 0.81) 78.12%
                    );

                    &:hover {
                        color: var(--offOrange);
                    }
                }
            }
        }

        .category-contents-wrapper {
            position: relative;
            padding-top: 5px;
            padding-bottom: 60px;
            padding-left: 3px;
            padding-right: 3px;

            .category-type {
                position: -webkit-sticky;
                position: sticky;
                //  .header (padding-top) + .category-buttons (padding-bottom) + 90px or use .header (height)
                top: calc(130px + 20px + 100px);
                font-size: 1.25rem;
                background: var(--black1);
                color: var(--white);
                padding-top: 30px;
                padding-bottom: 25px;
                z-index: 2;
            }

            .category-contents {
                height: 100%;
                width: 100%;
                display: -ms-grid;
                display: -moz-grid;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: auto;
                grid-auto-flow: row;
                gap: 60px 30px;
                z-index: 1;

                @media screen and (max-width: 768px) {
                    grid-template-columns: repeat(2, 1fr);
                }

                @media screen and (max-width: 426px) {
                    grid-template-columns: 1fr;
                }
            }

            .no-paylinks {
                h4 {
                    font-size: 1rem;
                    color: var(--grey2);
                    margin-bottom: 0;
                    text-align: center;
                }
            }
        }

        .pagination-wrapper {
            padding-top: 2.5rem;
            padding-bottom: 3.5rem;
            // pagination
        }

        .divider {
            width: 100%;
            margin-top: 0;
            margin-bottom: 2rem;
            border-color: var(--grey2-transp);
            opacity: 0.2;
        }

        .loading-wrapper {
            padding-bottom: 3.5rem;
        }
    }
}
</style>

